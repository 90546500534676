import React, { useEffect, useState } from "react";
import { Autocomplete, Box, Button, CardContent, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { fetchDataFromBack } from "../../../requests";
const RoundPointForm = (props) => {
  const { onResponseChange } = props;
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
  const [isLoadingCustomers, setIsLoadingCustomers] = useState(true);
  const [isLoadingServices, setIsLoadingServices] = useState(true);
  const [isLoadingRounds, setIsLoadingRounds] = useState(true);
  const [isLoadingRoundPoints, setIsLoadingRoundPoints] = useState(true);

  const [haveError, setHaveError] = useState(false);

  const [companies, setCompanies] = useState([]);
  const [customers, setCustomers] = useState([])
  const [services, setServices] = useState([])
  const [rounds, setRounds] = useState([])
  const [roundPoints, setRoundPoints] = useState([])

  //llama al metodo que realiza la peticion al backend a traves de requests.js
  const fetchData = async (method, endpoint, body) => {
    try {
      setHaveError(false)
      const response = await fetchDataFromBack(method, endpoint, body);

      return response;
    } catch (error) {
      setHaveError(true)
      return error;
    }
  };

  //Formik del formulario
  const QrDataForm = useFormik({
    initialValues: {
      company: null,
      customer: null,
      service: null,
      round: null,
      roundPoint: null,
      taskPoint: null,
      printText: false,
    },
    validationSchema: Yup.object().shape({
      company: Yup.object().required('Obligatorio'),
      customer: Yup.object().required('Obligatorio'),
      service: Yup.object().required('Obligatorio'),
      round: Yup.object().required('Obligatorio'),
      roundPoint: Yup.object().required('Obligatorio'),
      // taskPoint: Yup.object().required('Obligatorio'),
    }),

    onSubmit: (values, { setSubmitting }) => {
      // Update the response state in FormView
      onResponseChange(values);

      setSubmitting(false);
    },
    onReset: () => {
      QrDataForm.values.company = []
      QrDataForm.values.customer = []
      QrDataForm.values.service = []
      QrDataForm.values.round = []
      QrDataForm.values.roundPoint = []
      QrDataForm.values.taskPoint = []
      QrDataForm.values.printText = false;
    },
  });

  //initial petition for get companies data
  useEffect(() => {
    setIsLoadingCompanies(true);

    fetchData('GET', 'getCompanies')
      .then((response) => setCompanies(response))
      .finally(() => setIsLoadingCompanies(false));
  }, []);


  useEffect(() => {
    handleCustomers()
  }, [QrDataForm.values.company]);

  function handleCustomers() {
    if (QrDataForm.values.customer != null) {
      QrDataForm.setFieldValue('customer', null);
    }
    if (QrDataForm.values.service != null) {
      QrDataForm.setFieldValue('service', null);
    }
    if (QrDataForm.values.round != null) {
      QrDataForm.setFieldValue('round', null);
    }
    if (QrDataForm.values.roundPoint != null) {
      QrDataForm.setFieldValue('roundPoint', null);
    }

    setCustomers([])
    setServices([])
    setRounds([])
    setRoundPoints([])

    if (QrDataForm.values.company != null) {
      //petition for customers
      setIsLoadingCustomers(true);

      const body = {
        'company_code': QrDataForm.values.company.tareap_emp,
      }

      fetchData('POST', 'getCustomers', body)
        .then((response) => setCustomers(response))
        .finally(() => setIsLoadingCustomers(false));
    }
  }

  useEffect(() => {
    handleServices()
  }, [QrDataForm.values.customer]);

  function handleServices() {
    if (QrDataForm.values.service != null) {
      QrDataForm.setFieldValue('service', null);
    }
    if (QrDataForm.values.round != null) {
      QrDataForm.setFieldValue('round', null);
    }
    if (QrDataForm.values.roundPoint != null) {
      QrDataForm.setFieldValue('roundPoint', null);
    }

    setServices([])
    setRounds([])
    setRoundPoints([])

    if (QrDataForm.values.customer != null) {
      //petition for services
      setIsLoadingServices(true);

      const body = {
        'customer_code': QrDataForm.values.customer.customer_code,
        'company_id': QrDataForm.values.company.company.id
      }

      fetchData('POST', 'getServices', body)
        .then((response) => setServices(response))
        .finally(() => setIsLoadingServices(false));
    }
  }
  useEffect(() => {
    handleRounds()
  }, [QrDataForm.values.service]);

  function handleRounds() {
    if (QrDataForm.values.round != null) {
      QrDataForm.setFieldValue('round', null);
    }
    if (QrDataForm.values.roundPoint != null) {
      QrDataForm.setFieldValue('roundPoint', null);
    }

    setRounds([])
    setRoundPoints([])

    if (QrDataForm.values.service != null) {
      setIsLoadingRounds(true);

      const body = {
        'customer_contract_code': QrDataForm.values.service.customer_contract_code,
        'customer_contract_service_code': QrDataForm.values.service.customer_contract_service_code,
        'company_code': QrDataForm.values.company.tareap_emp,
      }

      fetchData('POST', 'getRounds', body)
        .then((response) => setRounds(response))
        .finally(() => setIsLoadingRounds(false));
    }
  }

  useEffect(() => {
    handleRoundPoints()
  }, [QrDataForm.values.round]);

  function handleRoundPoints() {
    if (QrDataForm.values.roundPoint != null) {
      QrDataForm.setFieldValue('roundPoint', null);
    }
    setRoundPoints([])

    if (QrDataForm.values.round != null) {
      setIsLoadingRoundPoints(true);

      const body = {
        'customer_contract_code': QrDataForm.values.service.customer_contract_code,
        'customer_contract_service_code': QrDataForm.values.service.customer_contract_service_code,
        'ronda_cod': QrDataForm.values.round.ronda_cod,
        'company_code': QrDataForm.values.company.tareap_emp,
      }

      fetchData('POST', 'getRoundPoints', body)
        .then((response) => setRoundPoints(response))
        .finally(() => setIsLoadingRoundPoints(false));
    }
  }

  useEffect(() => {
    handleTaskPoints()
  }, [QrDataForm.values.roundPoint]);

  function handleTaskPoints() {
    if (QrDataForm.values.taskPoint != null) {
      QrDataForm.setFieldValue('taskPoint', null);
    }

    if (QrDataForm.values.roundPoint != null) {
      const body = {
        'customer_contract_code': QrDataForm.values.service.customer_contract_code,
        'customer_contract_service_code': QrDataForm.values.service.customer_contract_service_code,
        'punto_cod': QrDataForm.values.roundPoint.punto_cod,
        'company_code': QrDataForm.values.company.tareap_emp,
      }

      fetchData('POST', 'getTaskPoints', body)
        .then((response) => QrDataForm.setFieldValue('taskPoint', response))
    }
  }
  function handleReset() {
    if (QrDataForm.values.company != null) {
      QrDataForm.resetForm()
    }
  }

  //useEffect that checks if the formik is fully filled, send the info
  //if not also send it to hide the qr code
  useEffect(() => {
    if (QrDataForm.values.taskPoint != null) {
      QrDataForm.handleSubmit()
    } else {
      onResponseChange([]);
    }
  }, [QrDataForm.values]);


  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      {!haveError ? (
        <>
          <Box
            sx={{ paddingBottom: '1.5em', width: '40%' }}
          >
            <Button variant="contained" sx={{ alignSelf: 'flex-start' }} onClick={handleReset}>
              <Typography>
                Reiniciar formulario
              </Typography>
            </Button>
            <Box
              sx={{ paddingTop: '2em' }}
            >
              <Autocomplete
                id='company'
                options={companies}
                getOptionLabel={(company) => company.tareap_emp + " - " + company.company.name}

                loading={isLoadingCompanies}
                loadingText='Cargando...'
                noOptionsText='No hay datos'

                renderInput={(params) => <TextField
                  sx={{ topPadding: '2em' }}
                  {...params}
                  label='Empresa'
                  variant='outlined'
                  size='small'
                  error={QrDataForm.touched.company && Boolean(QrDataForm.errors.company)}
                  helperText={QrDataForm.touched.company && QrDataForm.errors.company}
                />}

                onChange={(e, selectedCompany) => QrDataForm.setFieldValue('company', selectedCompany || null)}
                value={QrDataForm.values.company}
                size='small'
              />
            </Box>
          </Box>

          {QrDataForm.values.company && (
            <Box sx={{ paddingBottom: '2em', width: '40%' }}>
              <Autocomplete
                id='customer'
                options={customers}
                getOptionLabel={(customer) => customer.customer_code + " - " + customer.name}

                loading={isLoadingCustomers}
                loadingText='Cargando...'
                noOptionsText='No hay datos'

                renderInput={(params) => <TextField
                  sx={{ topPadding: '2em' }}
                  {...params}
                  label='Cliente'
                  variant='outlined'
                  size='small'
                  error={QrDataForm.touched.customer && Boolean(QrDataForm.errors.customer)}
                  helperText={QrDataForm.touched.customer && QrDataForm.errors.customer}
                />}

                onChange={(e, selectedCustomer) => QrDataForm.setFieldValue('customer', selectedCustomer || null)}
                value={QrDataForm.values.customer}
                size='small'
              />
            </Box>
          )}

          {QrDataForm.values.customer && QrDataForm.values.company && (
            <>
              <Box sx={{ paddingBottom: '2em', width: '40%' }}>
                <Autocomplete
                  id='service'
                  options={services}
                  getOptionLabel={((service) => service.customer_contract_service_code + " - " + service.description)}

                  loading={isLoadingServices}
                  loadingText='Cargando...'
                  noOptionsText='No hay datos'

                  renderInput={(params) => <TextField
                    sx={{ topPadding: '2em' }}
                    {...params}
                    label='Servicio'
                    variant='outlined'
                    size='small'
                    error={QrDataForm.touched.service && Boolean(QrDataForm.errors.service)}
                    helperText={QrDataForm.touched.service && QrDataForm.errors.service}
                  />}

                  onChange={(e, selectedService) => QrDataForm.setFieldValue('service', selectedService || null)}
                  value={QrDataForm.values.service}
                  size='small'
                />
              </Box>

              {QrDataForm.values.service && (
                <>
                  <Box sx={{ paddingBottom: '2em', width: '40%' }}>
                    <Autocomplete
                      id='round'
                      options={rounds}
                      getOptionLabel={(round) => round.ronda_cod + " - " + round.ronda_desc}
                      noOptionsText='No hay datos'

                      loading={isLoadingRounds}
                      loadingText='Cargando...'

                      renderInput={(params) => <TextField
                        sx={{ topPadding: '2em' }}
                        {...params}
                        label='Ronda'
                        variant='outlined'
                        size='small'
                        error={QrDataForm.touched.round && Boolean(QrDataForm.errors.round)}
                        helperText={QrDataForm.touched.round && QrDataForm.errors.round}
                      />}

                      onChange={(e, selectedRound) => QrDataForm.setFieldValue('round', selectedRound || null)}
                      value={QrDataForm.values.round}
                      size='small'
                    />
                  </Box>

                  {QrDataForm.values.round && (
                    <Box sx={{ paddingBottom: '2em', width: '40%' }}>
                      <Autocomplete
                        id='roundPoint'
                        options={roundPoints}
                        getOptionLabel={(roundPoints) => roundPoints.punto_cod + " - " + roundPoints.punto_desc}
                        noOptionsText='No hay datos'

                        loading={isLoadingRoundPoints}
                        loadingText='Cargando...'

                        renderInput={(params) => (
                          <TextField
                            sx={{ topPadding: '2em' }}
                            {...params}
                            variant='outlined'
                            label='Punto de ronda'
                            error={QrDataForm.touched.roundPoint && Boolean(QrDataForm.errors.roundPoint)}
                            helperText={QrDataForm.touched.roundPoint && QrDataForm.errors.roundPoint}
                          />
                        )}
                        onChange={(e, selectedRoundPoint) => QrDataForm.setFieldValue('roundPoint', selectedRoundPoint || null)}
                        value={QrDataForm.values.roundPoint}
                        size='small'
                      />
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <Typography>
          Ha ocurrido un error en la carga de datos. Por favor contacte con el administrador de la página.
        </Typography>
      )}
    </Box>
  );
};

export default RoundPointForm;

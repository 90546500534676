import React, { useEffect, useState } from 'react';
import { AppBar, Box, Button, Container, IconButton, LinearProgress, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { HeadProvider, Link as LinkHead, Meta, Title } from 'react-head'
import { MD5 } from 'crypto-js';
import headIcon from '../assets/favicon.ico'
import MenuIcon from '@mui/icons-material/Menu';  // Importa el ícono de menú

// Importación de fuentes de Roboto
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

// Importación de imagen de la barra de aplicaciones
import appBarImage from '../assets/LogoDimobaCorpo_Blanco.png'
import theme from '../theme';
import { useCookies } from 'react-cookie';
import { fetchDataFromBack } from '../requests';

// Lista de elementos del menú
const menuItems = [
  { id: 1, title: 'QR Servicios', to: '/qr-services', tool_code: 'QR_GENERATOR_SERVICES' },
  { id: 2, title: 'QR Almacenes', to: '/qr-warehouses', tool_code: 'QR_GENERATOR_WAREHOUSES' },

  // Agrega más elementos según sea necesario
];

function Root() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [abilities, setAbilities] = useState(null);
  const [loadingAbilities, setLoadingAbilities] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasAccess, setHasAccess] = useState(false);

  const [cookie] = useCookies(['']);


  const location = useLocation();

  const handleButtonClick = (id) => {
    setSelectedButtonIndex(id);
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  useEffect(() => {
    // Actualiza el botón seleccionado cuando cambia la ubicación
    switch (location.pathname) {
      case '/qr-servicies':
        setSelectedButtonIndex(1);
        break;
      case '/qr-warehouses':
        setSelectedButtonIndex(2);
        break;
      // Agrega más casos según sea necesario
      default:
        setSelectedButtonIndex(null);
        break;
    }
  }, [location]);

  useEffect(() => {
    if (cookie.poseidon_token == null) {
      const hash = MD5("QR_GENERATOR").toString();
      window.location.replace('https://login-dev.grupocontrol.com/' + hash + '/login');

    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (cookie.poseidon_token != null) {
          if (abilities == null) {
            const response = await fetchDataFromBack('GET', 'fetchAbilities', null);
            setAbilities(response.map(item => item.tool_code));
          }
        }
      } catch (error) {
        console.error('Error fetching abilities:', error);
      } finally {
        setLoadingAbilities(false);
      }
    };

    fetchData();
  }, [cookie.poseidon_token]);

  useEffect(() => {

    const handleAbilities = async () => {
      if (abilities.includes("SUPERADMIN")) {
        setIsAdmin(true);
      }
      menuItems.map(menuItem => {
        if (abilities.includes(menuItem.tool_code)) {
          setHasAccess(true)
        }
      })
    };
    if (abilities !== null) {
      handleAbilities();
    }

  }, [abilities]);

  return (
    <>
      <HeadProvider>
        <Title>QR Grupo Control</Title>
        <LinkHead rel='icon' href={headIcon} />
        <Meta name='description' content='Page for Qr generation' />
      </HeadProvider>
      {/* Barra de aplicaciones */}
      {abilities != null &&
        <>
          <AppBar position="static" sx={{ backgroundColor: theme.palette.primary.main }}>
            <Container maxWidth="xl">
              <Toolbar disableGutters>
                {/* Logo en la barra de aplicaciones para dispositivos de escritorio */}
                <IconButton
                  component={Link}
                  to="/"
                  sx={{
                    display: { xs: 'none', md: 'flex' },
                    mr: 1,
                    width: '15%',
                    height: '15%',
                  }}
                >
                  <img src={appBarImage} alt="Logo" style={{ height: '100%', width: 'auto' }} />
                </IconButton>

                {/* Menú desplegable en dispositivos móviles */}
                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                  <IconButton
                    size="large"
                    aria-label="Dropdown menu"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                  >
                    <MenuIcon /> {/* Ícono de menú */}
                  </IconButton>
                  <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                      display: { xs: 'block', md: 'none' },
                    }}
                  >
                    {/* Elementos del menú */}
                    {
                      loadingAbilities ?
                        isAdmin && (
                          menuItems.map((menuItem) => (
                            <MenuItem key={menuItem.id} component={Link} to={menuItem.to} selected={selectedButtonIndex === menuItem.id} onClick={handleCloseNavMenu}>
                              <Typography textAlign="center">{menuItem.title}</Typography>
                            </MenuItem>
                          ))
                        ) :
                        menuItems.filter(menuItem => abilities.includes(menuItem.tool_code))
                          .map((menuItem) => (
                            <MenuItem key={menuItem.id} component={Link} to={menuItem.to} selected={selectedButtonIndex === menuItem.id} onClick={handleCloseNavMenu}>
                              <Typography textAlign="center">{menuItem.title}</Typography>
                            </MenuItem>
                          ))
                    }
                  </Menu>
                </Box>

                {/* Logo en la barra de aplicaciones en dispositivos móviles */}
                <IconButton
                  component={Link}
                  to="/"
                  sx={{
                    display: { xs: 'block', md: 'none' },  // Mostrar solo en dispositivos móviles
                    mr: 1,
                    width: '15%',
                    height: '15%',
                  }}
                >
                  <img src={appBarImage} alt="Logo" style={{ height: '100%', width: 'auto' }} />
                </IconButton>

                {/* Elementos del menú en la barra de aplicaciones para dispositivos de escritorio */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  {
                    !loadingAbilities
                      ? isAdmin && (
                        menuItems.map((menuItem) => (
                          <Button
                            key={menuItem.id}
                            component={Link} to={menuItem.to}
                            sx={{
                              borderRadius: 0,
                              my: 2,
                              color: selectedButtonIndex === menuItem.id ? 'white' : 'lightgrey',
                              display: 'block',
                              borderBottom: selectedButtonIndex === menuItem.id ? '2px solid black' : null
                            }}
                            onClick={() => handleButtonClick(menuItem.id)}
                            variant="text"
                            color="primary"
                          >
                            {menuItem.title}
                          </Button>
                        ))
                      )
                      : menuItems.filter(menuItem => abilities.includes(menuItem.tool_code))
                        .map((menuItem) => (
                          <Button
                            key={menuItem.id}
                            component={Link} to={menuItem.to}
                            sx={{
                              borderRadius: 0,
                              my: 2,
                              color: selectedButtonIndex === menuItem.id ? 'white' : 'lightgrey',
                              display: 'block',
                              borderBottom: selectedButtonIndex === menuItem.id ? '2px solid black' : null
                            }}
                            onClick={() => handleButtonClick(menuItem.id)}
                            variant="text"
                            color="primary"
                          >
                            {menuItem.title}
                          </Button>
                        ))
                  }

                </Box>
              </Toolbar>
            </Container>
          </AppBar>

          {/* Contenido principal */}
          <Container maxWidth="xl">
            <Toolbar />

            {loadingAbilities ? (
              <Typography variant="h3" align="center" sx={{ marginTop: '2em' }}>
                Cargando, espere
                <LinearProgress />
              </Typography>
            ) : (
              hasAccess || isAdmin ? (
                <Outlet context={{ isAdmin, hasAccess, abilities }} />
              ) : (
                <Typography variant="h3" align="center" sx={{ marginTop: '2em' }}>
                  Solicite acceso al administrador
                </Typography>
              )
            )}

          </Container>
        </>
      }

    </>
  );
}

export default Root;
